import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import { Typography, Divider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {StyledModal, StyledModalBody, StyledModalFooter, StyledModalHeader, StyledModalInnerWrapper, modalStyles } from '../GraphModal/GraphModal';
import { EditInterview } from './EditInterview.jsx';

const EDIT_EDGE_MUTATION = gql`
mutation EditEdge($edgeId: ID!, $notes: String!, $dateMet: Date!, $images: [ImageInput], $interviewQuestions: [InterviewQuestionInput]) {
  editEdge(edgeId: $edgeId, notes: $notes, dateMet: $dateMet, images: $images, interviewQuestions: $interviewQuestions) {
    _id
  }
}
`;

const imageStyle = {
  width: 100,
  height: 100,
  margin: '0 auto',
  display: 'block',
};

const dashedLineStyle = {
  border: '1px dashed #000',
  width: '100%',
  height: '0px',
};

export const EditEdgeModal = ({
  selectedEdge,
  isModalOpen,
  setIsModalOpen,
  refetchGraph,
  person1,
  person2,
}) => {
  const [editEdge] = useMutation(EDIT_EDGE_MUTATION);
  const [editedEdgeNote, setEditedEdgeNote] = useState('');
  const [editedEdgeDateMet, setEditedEdgeDateMet] = useState();

  const [interviewQuestions, setInterviewQuestions] = useState([]);


  const saveChanges = async () => {
    console.log(selectedEdge);
    await editEdge({ variables: { edgeId: selectedEdge._id, notes: editedEdgeNote, dateMet: editedEdgeDateMet, 
      interviewQuestions: interviewQuestions.map(({question, answer, personId}) => ({question, answer, personId})) } });
    await refetchGraph();
    setIsModalOpen(false);
  };

  useEffect(() => {
    setEditedEdgeNote(selectedEdge?.note);
    setEditedEdgeDateMet(selectedEdge?.dateMet);
    setInterviewQuestions(selectedEdge?.interviewQuestions);
  }, [selectedEdge]);


  if (!selectedEdge || !isModalOpen) return null;

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <StyledModal>
        <StyledModalInnerWrapper>
          <StyledModalHeader>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                justifyItems: 'center',
                marginBottom: '1rem',
                width: '95%',
                marginTop: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt={person1?.name}
                  src={person1?.imageURL || `https://robohash.org/${person1?.name}`}
                  sx={imageStyle}
                />
                <Typography variant="h5" align="center" gutterBottom>
                  {person1?.name}
                </Typography>
              </Box>
              <Box flexItem sx={{
                display: 'flex',
                width: '80%',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '50px',
              }}>
                <Divider style={dashedLineStyle} flexItem />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt={person2?.name}
                  src={person2?.imageURL || `https://robohash.org/${person2?.name}`}
                  sx={imageStyle}
                />
                <Typography variant="h5" align="center">
                  {person2?.name}
                </Typography>
              </Box>
            </Box>
          </StyledModalHeader>
          <StyledModalBody>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                label="When We Met"
                defaultValue={dayjs(selectedEdge?.dateMet)}
                onChange={(e) => setEditedEdgeDateMet(e)}
              />
            </LocalizationProvider>
            <TextField
              type="text"
              multiline
              defaultValue={editedEdgeNote}
              onChange={(e) => setEditedEdgeNote(e.target.value)}
              label="How We Met"
            />
            {interviewQuestions && <EditInterview
              questions={interviewQuestions}
              setQuestions={setInterviewQuestions}
              person1={person1}
              person2={person2}

            />}
          </StyledModalBody>
          <StyledModalFooter>
            <Button variant="contained" onClick={saveChanges}>Save</Button>
            <Button variant="contained" onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </StyledModalFooter>
        </StyledModalInnerWrapper>
      </StyledModal>
    </Modal>
  );
};
