import React, { useEffect, useMemo, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import './graph.css';
import { gql, useMutation, useQuery } from '@apollo/client';
import { EditPersonModal } from './PersonModal/EditPersonModal';
import { EditEdgeModal } from './EdgeModal/EditEdgeModal';
import { Box, Button, Drawer, List, ListItem, ListItemText, SnackbarContent } from '@mui/material';
import { CreatePersonModal } from './CreatePersonModal/CreatePersonModal';
import { useTheme } from '@emotion/react';
import useIsTouchscreen, { getColor } from './utils';
import Snackbar from '@mui/material/Snackbar';
import { set } from 'lodash';

const PEOPLE_QUERY = gql`
  query People {
    people {
      _id
      name
      imageURL
      funFact
    }
    edges {
      _id
      person1
      person2
      notes
      dateMet
      images {
        imageURL
        caption
      }
      interviewQuestions {
            question
            answer
            personId
      }
    }
  }
`;

export default function Graph() {
  const { data: graphData, refetch: refetchGraph } = useQuery(PEOPLE_QUERY);

  const [data, setData] = useState({ nodes: [], links: [] });
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdgeModalOpen, setIsEdgeModalOpen] = useState(false);

  const theme = useTheme();

  const isTouchDevice = useIsTouchscreen();

  const [selectedLink, setSelectedLink] = useState(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleNodeClick = (node) => {
    setSelectedNode(node);
    setIsModalOpen(true);
  };

  const handleEdgeClick = (edge) => {
    setSelectedEdge(edge);
    if (isTouchDevice) {
        setIsSnackbarOpen(true);
    } else {
        setIsEdgeModalOpen(true);
    }
  };

  const idToPerson = useMemo(() => {
    if (!graphData) return {};
    const { people } = graphData;
    return people.reduce((acc, person) => {
      acc[person._id] = person;
      return acc;
    }, {});
  }, [graphData]);

  const idToPersonImage = useMemo(() => {
    if (!graphData) return {};
    const { people } = graphData;
    return people.reduce((acc, person) => {
      const img = new Image();
      const linkedinUrl = person.imageURL?.indexOf('licdn') > -1;
      img.src = !linkedinUrl ? person.imageURL || `https://robohash.org/${person.name}` : `https://robohash.org/${person.name}`;
      img.style.borderRadius = '50%';
      acc[person._id] = img;
      return acc;
    }, {});
  }, [graphData]);

  useEffect(() => {
    if (!graphData) return;

    const { people, edges } = graphData;
    // Fetch random user data from the API
    const nodes = people.map((user) => ({
      id: user._id,
      name: user.name,
      // image: user.imageUrl,
      image: user.imageURL || `https://robohash.org/${user.name}`,
    }));

    // Set initial positions for the nodes (optional)
    nodes.forEach((node, index) => {
      if (index) {
        node.x = Math.random() * 200 - 100; // Set initial x position
        node.y = Math.random() * 200 - 100; // Set initial y position
      } else {
        node.x = 0; // Set initial x position
        node.y = 100; // Set initial y position
      }
    });

    const links = edges.map((edge) => ({
      ...edge,
      source: edge.person1,
      target: edge.person2,
      note: edge.notes,
      dateMet: edge.dateMet,
      id: edge._id,
    }));

    setData({ nodes, links });
  }, [graphData]);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <div key="bottom">
      <div key="bottom" className="graph-container">
        <CreatePersonModal
          isModalOpen={isPanelOpen}
          setIsModalOpen={setIsPanelOpen}
          refetchGraph={refetchGraph}
          people={graphData?.people}
        />
        <EditPersonModal
          selectedNode={selectedNode}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetchGraph={refetchGraph}
          headerBackgroundColor={selectedNode && getColor(selectedNode?.id)}
        />
        <EditEdgeModal
          selectedEdge={selectedEdge}
          isModalOpen={isEdgeModalOpen}
          setIsModalOpen={setIsEdgeModalOpen}
          refetchGraph={refetchGraph}
          person1={idToPerson[selectedEdge?.source.id]}
          person2={idToPerson[selectedEdge?.target.id]}
        />
        <ForceGraph2D
          graphData={data}
          nodeLabel={(node) => node.name}
          onNodeClick={(node) => handleNodeClick(node)}
          onLinkClick={(link) => handleEdgeClick(link)}
            nodeCanvasObject={(node, ctx, globalScale) => {
            // Draw circular nodes
            const size = 15; // Set the size of the circular nodes
            ctx.beginPath();
            ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI);
            ctx.fillStyle = getColor(node.id); // Set node color
            ctx.fill();
            ctx.closePath();

            // Draw rounded image
            const imgSize = size - 2; // Adjust size for border
            ctx.save();
            ctx.beginPath();
            ctx.arc(node.x, node.y, imgSize / 2, 0, 2 * Math.PI);
            ctx.clip();
            ctx.drawImage(
              idToPersonImage[node.id],
              node.x - imgSize / 2,
              node.y - imgSize / 2,
              imgSize,
              imgSize
            );
            ctx.closePath();
            ctx.restore();
          }}
          linkLabel={(link) => link.note.length > 20 ? link.note.slice(0, 20) + '...' : link.note}
          linkDirectionalArrowLength={0}
          linkCanvasObject={(link, ctx, globalScale) => {
                // Customize link rendering (thicker and red)
                ctx.beginPath();
                ctx.strokeStyle = theme.palette.error.main; // Set link color to red
                ctx.lineWidth = 1.5; // Set the line width to make it thicker
                ctx.moveTo(link.source.x, link.source.y);
                ctx.lineTo(link.target.x, link.target.y);
                ctx.stroke();
          }}
        />
        <Button
          variant="contained"
          color="primary"
          className="floating-button"
          onClick={togglePanel}
        >
          {'Add Person'}
        </Button>

        <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000} // Adjust as needed
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        backgroundColor={theme.palette.info.main}
      >
        <SnackbarContent
          sx={{ backgroundColor: theme.palette.info.main, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          message={
            <Box sx={{ display: 'flex', width:"100%", justifyContent: 'space-between' }}>

             <Button
                variant='contained'
                color="primary"
                size="small"
                onClick={() => {
                  // Handle button click here
                    setIsEdgeModalOpen(true);
                    setIsSnackbarOpen(false);
                }}
              >
                View Story
              </Button>
            
              <Button
                variant='contained'
                color="primary"
                size="small"
                onClick={() => {
                  // Handle button click here
                    setIsEdgeModalOpen(true);
                    setIsSnackbarOpen(false);
                }}
              >
                Edit Edge
              </Button>
            
            </Box>
          }
        />
      </Snackbar>
      </div>
    </div>
  );
}
