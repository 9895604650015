import React, { useRef, useEffect, useMemo, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import './graph.css';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Drawer, List, ListItem, ListItemText, SnackbarContent } from '@mui/material';
import { useTheme } from '@emotion/react';
import useIsTouchscreen, { getColor } from './utils';
import PersonDrawer from './PersonDrawer/PersonDrawer';
import { EdgeDrawer } from './EdgeDrawer/EdgeDrawer';
import { mapPersonToEdges } from './utils';
import { set } from 'lodash';

const PEOPLE_QUERY = gql`
  query People {
    people {
      _id
      name
      imageURL
      funFact
      instagram
      locationX
      locationY
    }
    edges {
        _id
        person1
        person2
        notes
        dateMet
        images {
            imageURL
            caption
        }
        interviewQuestions {
            question
            answer
            personId
        }
    }
  }
`;

const UPDATE_LOCATION_MUTATION = gql`
mutation EditEdge($nodesToUpdate: [NodeInput]) {
  updateLocation(nodesToUpdate: $nodesToUpdate)
}
`

export default function GraphRead() {
  const { data: graphData, refetch: refetchGraph } = useQuery(PEOPLE_QUERY);
  const [updateLocation] = useMutation(UPDATE_LOCATION_MUTATION);

  const [data, setData] = useState({ nodes: [], links: [] });

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [idToNode] = useState({});

  const graphRef = useRef(null);

  const theme = useTheme();

  const handleNodeClick = (node) => {
    if (selectedNode?._id === node._id) {
      setOpen(true)
      return;
    }
    setSelectedEdge(null);
    setSelectedNode(node);    // setIsModalOpen(true);
  };

  const handleEdgeClick = (edge) => {
    if (selectedEdge?._id === edge._id) {
      setOpen(true)
      return;
    }
      
      setTimeout(() => {
          setSelectedEdge(edge);
        }, selectedNode ? 200: 0)
      setSelectedNode(null);
  };

  const idToPerson = useMemo(() => {
    if (!graphData) return {};
    const { people } = graphData;
    return people.reduce((acc, person) => {
      acc[person._id] = person;
      return acc;
    }, {});
  }, [graphData]);

  const idToPersonImage = useMemo(() => {
    if (!graphData) return {};
    const { people } = graphData;
    return people.reduce((acc, person) => {
      const img = new Image();
      const linkedinUrl = person.imageURL?.indexOf('licdn') > -1;
      img.src = !linkedinUrl ? person.imageURL || `https://robohash.org/${person.name}` : `https://robohash.org/${person.name}`;
      img.style.borderRadius = '50%';
      acc[person._id] = img;
      return acc;
    }, {});
  }, [graphData]);

  const [personToEdges, setPersonToEdges] = useState({});

  useEffect(() => {
    if (!graphData) return;



    const { people, edges } = graphData;
    // Fetch random user data from the API
    const p2E = mapPersonToEdges(people,edges)
    setPersonToEdges(p2E);
    console.log(people)
    const nodes = people.map((user) => ({
      ...user,
      id: user._id,
      name: user.name,
      image: user.imageURL || `https://robohash.org/${user.name}`,
      x: user.locationX ||  Math.random() * 200 - 100,
      y: user.locationY ||  Math.random() * 200 - 100,
    }));

    const links = edges.map((edge) => ({
      ...edge,
      source: edge.person1,
      target: edge.person2,
      note: edge.notes,
      dateMet: edge.dateMet,
      id: edge._id,
    }));



    setData({ nodes, links });
  }, [graphData]);


  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    if (!newOpen) {
      setTimeout(() => {
        setSelectedEdge(null);
        setSelectedNode(null);
      },200);
    }
  };

      if (graphRef.current) {
      // Access the force simulation
    //   const simulation = graphRef.current.stopAnimation();
      
      // Stop the simulation immediately
    //   if(simulation){
    //   simulation.stop();
    //   }
    }

  const [zoomGraph, setZoomGraph] = useState(1);
  const [firstZoom, setFirstZoom] = useState(true);
  const handleEngineStop = () => {
    // Access the d3Zoom instance from the ForceGraph2D component
    const d3Zoom = graphRef.current.zoom;

    // Find the node you want to zoom in on (replace 'node-1' with the actual node ID)
    

    // Check if the target node exists
    // setZoomGraph(5)
    if (d3Zoom && graphData?.people[0] && firstZoom) {
      graphRef.current.centerAt(graphData.people[0].locationX, graphData.people[0].locationY, 500)
      setFirstZoom(false);
      setTimeout(() => {
        d3Zoom(5, 500);
    }, 500);
    setTimeout(() => {
        setSelectedNode(graphData.people[0])
    }, 1000);
    }
  };

  window.a = idToNode;
  return (
    <div>
        {/* <Button onClick={() => {
            const nodesToUpdate = graphData.people.map((person) => ({
                id: person._id,
                locationX: idToNode[person._id].x,
                locationY: idToNode[person._id].y,
            }))
            updateLocation({ variables: { nodesToUpdate } });

        }}> Save Location </Button> */}
        {<EdgeDrawer
            selectedEdge={selectedEdge}
            onClose={() => setSelectedEdge(null)}
            person1={idToPerson[selectedEdge?.person1]}
            person2={idToPerson[selectedEdge?.person2]}
            toggleDrawer={toggleDrawer}
            onPersonClick={(person) => {
                setOpen(false);
                graphRef.current.centerAt(person.locationX, person.locationY, 500)

                setTimeout(() => {
                    setSelectedEdge(null);
                    setSelectedNode(person);
                    setOpen(true);
                },200);
            }}
            open={open && selectedEdge}
        />}
        {<PersonDrawer
            personSelected={selectedNode}
            onEdgeClick={(edge) => {
                setOpen(false);
                setTimeout(() => {
                    setSelectedNode(null);
                    setSelectedEdge(edge);
                    setOpen(true);
                },200);
            }}
            onClose={() => setSelectedNode(null)}
            idToPerson={idToPerson}
            toggleDrawer={toggleDrawer}
            open={open && selectedNode}
            edges={personToEdges[selectedNode?._id]}
        />}
      <div className="graph-container">
        <ForceGraph2D
          ref={graphRef}
          cooldownTicks={60}
          engineOptions={{ worker: true }}
          onEngineStop={handleEngineStop}
          graphData={data}
          nodeLabel={(node) => node.name}
          centerAt={graphData?.people[0]?.locationX ? [graphData.people[0].locationX, graphData.people[0].locationY] : [0,0]}
          zoom={zoomGraph}
          onNodeClick={(node) => handleNodeClick(node)}
          onLinkClick={(link) => handleEdgeClick(link)}
            nodeCanvasObject={(node, ctx, globalScale) => {
            idToNode[node._id] = node;
            // Draw circular nodes
            const size = 15; // Set the size of the circular nodes
            ctx.beginPath();
            ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI);
            ctx.fillStyle = getColor(node.id); // Set node color
            ctx.fill();
            ctx.closePath();

            // Draw rounded image
            const imgSize = size - 2; // Adjust size for border
            ctx.save();
            ctx.beginPath();
            ctx.arc(node.x, node.y, imgSize / 2, 0, 2 * Math.PI);
            ctx.clip();
            ctx.drawImage(
              idToPersonImage[node.id],
              node.x - imgSize / 2,
              node.y - imgSize / 2,
              imgSize,
              imgSize
            );
            ctx.closePath();
            ctx.restore();
          }}
          linkLabel={(link) => link.note.length > 20 ? link.note.slice(0, 20) + '...' : link.note}
          linkDirectionalArrowLength={0}
          linkCanvasObject={(link, ctx, globalScale) => {
                // Customize link rendering (thicker and red)
                ctx.beginPath();
                ctx.strokeStyle = theme.palette.error.main; // Set link color to red
                ctx.lineWidth = 1.5; // Set the line width to make it thicker
                ctx.moveTo(link.source.x, link.source.y);
                ctx.lineTo(link.target.x, link.target.y);
                ctx.stroke();
          }}
        />
      </div>
  

    </div>
  );
}
